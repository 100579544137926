// Landing.jsx
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalView from '../container/ModalView.js';
import LandingContent from '../container/LandingContent.js';

import { getIsInIframe } from '../resource/getJsEnvironment.js';
import { ModalId } from '../resource/mixpanel.js';

const isInIframe = getIsInIframe();

export class Landing extends Component {
  componentDidMount() {
    this.handleModal();
  }
  componentDidUpdate({
    token: prevToken,
    isRemoteConfigMerged: prevMerged,
    shouldVerifyAge: prevShouldVerifyAge,
    isInNeedLandingCountries: prevIsInNeedLandingCountries,
  }) {
    const {
      token,
      isRemoteConfigMerged,
      shouldVerifyAge,
      isInNeedLandingCountries,
    } = this.props;
    if (
      (token && token !== !prevToken) ||
      (isRemoteConfigMerged && isRemoteConfigMerged !== prevMerged) ||
      shouldVerifyAge !== prevShouldVerifyAge ||
      isInNeedLandingCountries !== prevIsInNeedLandingCountries
    ) {
      this.handleModal();
    }
  }
  componentWillUnmount() {
    this.handleClose();
  }
  handleModal() {
    const {
      token,
      isInNeedLandingCountries,
      isDisplaying,
      isRemoteConfigMerged,
      shouldVerifyAge,
    } = this.props;

    const isGuest = !token;
    const shouldAlwaysClose = !isGuest || isInIframe;
    if (shouldAlwaysClose) {
      if (isDisplaying) {
        this.handleClose();
      }
      return;
    }

    if (!isRemoteConfigMerged) {
      return;
    }

    const shouldDisplay = shouldVerifyAge && isInNeedLandingCountries;
    if (shouldDisplay && !isDisplaying) {
      this.handleOpen();
    } else if (isDisplaying && !shouldDisplay) {
      this.handleClose();
    }
  }
  handleOpen() {
    this.props.open();
    document.body.style.overflow = 'hidden';
  }
  handleClose() {
    document.body.style.overflow = 'auto';
    this.props.close();
  }
  render() {
    return (
      <ModalView
        id="LandingPageModal"
        modalId={ModalId.Landing.LandingPage2024Iwin}
        render={() => <LandingContent />}
      />
    );
  }
}

Landing.propTypes = {
  token: PropTypes.string,
  shouldVerifyAge: PropTypes.bool,
  isInNeedLandingCountries: PropTypes.bool,
  isDisplaying: PropTypes.bool,
  open: PropTypes.func,
  close: PropTypes.func,
  isRemoteConfigMerged: PropTypes.bool,
};

Landing.defaultProps = {
  token: '',
  shouldVerifyAge: false,
  isInNeedLandingCountries: false,
  isDisplaying: false,
  open: () => null,
  close: () => null,
  isRemoteConfigMerged: false,
};

export default Landing;
