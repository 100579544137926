// LandingContent.js
'use strict';
import { connect } from 'react-redux';

import LandingContent from '../component/LandingContent.jsx';

import login from '../action/login.js';

import getModalData from '../selector/getModalData.js';
import getOperationData from '../selector/getOperationData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import { BRANDING_NAME } from '../RemoteConfigKeys.js';

import { breakpoint } from '../style/variables.js';

const mapStateToProps = state => {
  const deviceWidth = getOperationData(state, ['device'], 'width');
  const status = getModalData(state, 'LandingPageModal', 'status');
  return {
    status,
    isOnMobile: deviceWidth <= breakpoint.mobile,
    brandname: getRemoteConfigData(state, BRANDING_NAME),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: () => dispatch(login()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingContent);
